<script setup lang="ts">
import { useProgrammatic } from '@oruga-ui/oruga-next';
import { onMounted, ref } from 'vue';

import SitePolicy from './modals/SitePolicy.vue';
import TotpModal from './modals/SetupTotpModal.vue';
import AccountEMailAddress from './modals/AccountEMailAddress.vue';
import AccountChangePassword from './modals/AccountChangePassword.vue';

import { useUserStore } from '~/stores/user';
import { useCacheStore } from '~/stores/cache';
import type { ent_AppGroup } from '~/api/generated';
import { AppService } from '~/api/generated';

const appsData = ref<ent_AppGroup[]>([]);

const userStore = useUserStore();
const cache = useCacheStore();
const oruga = useProgrammatic();

const forceAcceptPolicy = import.meta.env.VITE_FORCE_ACCEPT_POLICY?.toLowerCase() === 'true';
const forcePasswordChange = import.meta.env.VITE_FORCE_PASSWORD_CHANGE?.toLowerCase() === 'true';
const force2faTeachers = import.meta.env.VITE_FORCE_2FA_TEACHERS?.toLowerCase() === 'true';

function openEmailModal() {
  oruga.oruga.modal.open({
    props: {
      icon: 'i-fa6-solid-envelope',
      size: 'medium',
    },
    component: AccountEMailAddress,
    hasModalCard: true,
    trapFocus: true,
    scroll: 'keep',
    animation: 'fade',
  });
}

function openPasswordResetModal() {
  oruga.oruga.modal.open({
    props: {
      title: 'Passwort setzen',
      icon: 'i-fa6-solid-key',
      size: 'medium',
      closeable: false,
    },
    component: AccountChangePassword,
    hasModalCard: true,
    trapFocus: true,
    canCancel: false,
    scroll: 'keep',
    animation: 'fade',
    events: {
      close: () => {
        openEmailModal();
      },
    },
  });
}

function openPolicyModal() {
  oruga.oruga.modal.open({
    props: {
      title: 'Nutzungsbedingungen',
      icon: 'i-fa6-solid-book',
      size: 'full',
      closeable: false,
    },

    component: SitePolicy,
    hasModalCard: true,
    trapFocus: true,
    canCancel: false,
    scroll: 'keep',
  });
}

function open2faModal() {
  oruga.oruga.modal.open({
    props: {
      title: 'Zwei-Faktor-Authentifizierung',
      icon: 'i-fa6-solid-key',
      size: 'medium',
      closeable: false,
    },

    component: TotpModal,
    hasModalCard: true,
    trapFocus: true,
    canCancel: false,
    scroll: 'keep',
  });
}

onMounted(async () => {
  // get app data from api
  appsData.value = await AppService.getApps(false, await userStore.getToken());

  // update cache
  cache.apps = appsData.value;
});

/** handle policy modal */
watch(userStore, () => {
  if (userStore.isAuthenticated) {
    if (!userStore.user?.sitePolicyAccepted && forceAcceptPolicy) {
      openPolicyModal();
    }
    else if (!userStore.user?.passwordSet && forcePasswordChange && userStore.user?.id !== '') {
      openPasswordResetModal();
    }
    else if (!userStore.user?.secondFactorSet
      && userStore.user?.type === 'L'
      && force2faTeachers) {
      open2faModal();
    }
  }
}, { immediate: true });
</script>

<template>
  <div class="flex place-content-center justify-center pt-8">
    <div class="flex flex-col gap-4 pt-5">
      <Grid :edit-mode="false" />
    </div>
  </div>
</template>
