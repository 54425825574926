import { defineStore } from 'pinia';
import type { ent_App, ent_AppGroup } from '~/api/generated';

export interface GridRow {
  apps: ent_App[][]
  categoryData?: ent_AppGroup
}

interface HoveredElement {
  row: number | null
  column: number | null
}

export const useGridStore = defineStore('grid', () => {
  /** App grid */
  const categories = ref<GridRow[]>([]);

  /** Fixed number of columns in grid */
  const numberOfColumns = 10;

  /** Max number of columns in grid */
  const maxRows = 8;

  /** True if one element is being dragged */
  const isAppDragging = ref(false);

  /** Current app being hovered */
  const hoveredElement = ref<HoveredElement>({ row: null, column: null });

  return {
    categories,
    isAppDragging,
    numberOfColumns,
    maxRows,
    hoveredElement,
  };
}, {
  persist: true,
});
