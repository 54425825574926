<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean
  password: string
  username?: string
}>();

const emit = defineEmits(['update:modelValue']);

function checkLength() {
  return (props.password.length >= 8);
}
function checkUpperCase() {
  const re = /[A-Z]/;
  return re.test(props.password);
}

function checkLowerCase() {
  const re = /[a-z]/g;
  return (props.password.match(re));
}

function checkNumbers() {
  const re = /\d/;
  return (re.test(props.password));
}

function checkSpecialCharacters() {
  const re = /[^A-Z0-9]+/i;
  return re.test(props.password);
}

function checkUsername() {
  if (props.username) {
    const lowerCasePass = props.password.toLowerCase();

    let containsUsername = lowerCasePass.includes(props.username);
    let pos = 0;
    while ((props.username.length - pos) >= 3) {
      const sub = props.username.slice(pos, pos + 3);
      if (lowerCasePass.includes(sub)) {
        containsUsername = true;
        break;
      }
      pos++;
    }
    return !containsUsername;
  }
  return true;
}

watch(() => props.password, () => {
  emit('update:modelValue', checkLength() && checkUpperCase() && checkLowerCase() && checkNumbers() && checkSpecialCharacters() && checkUsername());
});
</script>

<template>
  <div class="flex flex-col text-xs">
    <div class="flex flex-row gap-1">
      <div :class="checkLength() ? 'i-fa6-solid-check bg-green-600' : 'i-fa6-solid-xmark bg-red-600'" />
      Das Kennwort muss mindestens neun Zeichen lang sein.
    </div>
    <div class="flex flex-row gap-1">
      <div :class="checkUpperCase() ? 'i-fa6-solid-check bg-green-600' : 'i-fa6-solid-xmark bg-red-600'" />
      <div>Großbuchstaben (A bis Z)</div>
    </div>
    <div class="flex flex-row gap-1">
      <div :class="checkLowerCase() ? 'i-fa6-solid-check bg-green-600' : 'i-fa6-solid-xmark bg-red-600'" />
      <div>Kleinbuchstaben (a bis z)</div>
    </div>
    <div class="flex flex-row gap-1">
      <div :class="checkNumbers() ? 'i-fa6-solid-check bg-green-600' : 'i-fa6-solid-xmark bg-red-600'" />
      <div>Zahlen zur Basis 10 (0 bis 9)</div>
    </div>
    <div class="flex flex-row gap-1">
      <div :class="checkSpecialCharacters() ? 'i-fa6-solid-check bg-green-600' : 'i-fa6-solid-xmark bg-red-600'" />
      <div>Nicht alphabetische Zeichen (zum Beispiel !, $, #, %)</div>
    </div>
    <div v-if="props.username" class="flex flex-row gap-1">
      <div :class="checkUsername() ? 'i-fa6-solid-check bg-green-600' : 'i-fa6-solid-xmark bg-red-600'" />
      <div>Enthält keine Abschnitte des eigenen Kontonamens</div>
    </div>
  </div>
</template>
