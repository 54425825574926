<script  setup lang="ts">
interface Props {
  title: string
  icon?: string
  hideFooter?: boolean
  size?: string
  closeable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  size: 'medium',
  icon: '',
  hideFooter: false,
  closeable: true,
});

defineEmits<{
  (e: 'close'): void
  (e: 'submit'): void
}>();

const cssSize = computed(() => {
  switch (props.size) {
    case 'small':
      return 'w-[300px]';
    case 'medium':
      return 'w-[500px]';
    case 'large':
      return 'w-80%';
    case 'full':
      return 'w-full';
    default:
      return '';
  }
});
</script>

<template>
  <div :class="`bg-white dark:bg-white bg-opacity-95 h-full rounded-lg shadow-2xl py-4 lt-sm:w-full ${cssSize}`">
    <form @submit.prevent="$emit('submit')">
      <header class="w-full flex justify-between pb-2 pl-4">
        <div class="flex flex-row gap-2">
          <div v-if="props.icon !== ''" class="flex items-center self-center justify-center text-center">
            <div :class="`text-xl w-6 h-6 ${props.icon}`" />
          </div>
          <span class="text-lg font-semibold">{{ props.title }}</span>
        </div>
        <div v-if="props.closeable" class="dark:hover:bg-ui-hover rounded-l-lg bg-light-primary px-2 py-1 hover:bg-light-hover" @click="$emit('close')">
          <div class="i-fa6-solid-xmark text-lg hover:cursor-pointer" />
        </div>
      </header>
      <div class="dotted-spaced h-1" />
      <div class="max-h-[60vh] overflow-auto px-4 pt-4">
        <slot name="body" />
      </div>
      <div v-if="!hideFooter" class="px-4 pt-4">
        <slot name="footer" />
      </div>
    </form>
  </div>
</template>
